<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Объявления</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Объявления пользователей</h5>
        </div>

        <div class="d-flex flex-column justify-content-center">
          <a-input ref="searchInput" v-model="search" placeholder="Поиск">
            <a-icon slot="prefix" type="search" />
            <a-tooltip slot="suffix" title="Поиск по типу, марке, модели, уникальному ID">
              <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </a-input>
        </div>
      </div>

      <div class="card-body">
        <div class="users_statistics">
          <h4 class="mb-3">Статистика обьявлений</h4>
          <div class="users_statistics--item" v-for="stat in adsStatistics" :key="stat.id">
            <div @click="date = stat.id">{{ stat.name }}</div>
            <div>{{ getCount(stat.alias) }}</div>
          </div>
        </div>

        <products-table :list="list" @editItem="editItem" @toPreview="toPreview" @removeItem="removeItem" :types="types" :paginate="pagination" @handleTableChange="handleTableChange" />
      </div>
      <product-edit-modal ref="editModal" />
      <product-preview-modal ref="previewModal" />
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import ProductEditModal from '@/components/products/ProductEditModal'
import ProductPreviewModal from '@/components/products/ProductPreviewModal'
import ProductsTable from '@/components/products/ProductsTable'

import adsStatistics from '@/entities/statistics'

export default {
  name: 'Products',

  components: {
    ProductEditModal,
    ProductsTable,
    ProductPreviewModal,
  },

  data() {
    return {
      adsStatistics,
      date: null,
      statistics: {},
      list: [],
      search: '',
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
      loading: false,
      types: [],
    }
  },

  created() {
    this.getList()
  },

  watch: {
    search() {
      this.getList()
    },

    date() {
      this.getList()
    },
  },

  computed: {
    getPath() {
      const path = this.$route.path
      const pathArray = path.split('/')
      return pathArray[pathArray.length - 1]
    },

    params() {
      return {
        page: this.pagination.current,
        search: this.search ? this.search : null,
        date: this.date ? this.date : null,
      }
    },
  },

  methods: {
    async getList() {
      const params = this.params

      // eslint-disable-next-line camelcase
      const { products, today, week, month, three_month } = (await apiClient.get('admin/products', { params })).data.data

      this.statistics = {
        today,
        week,
        month,
        three_month,
      }

      this.list = products.data
      this.pagination = {
        current: products.pagination.current_page,
        total: products.pagination.total,
        pageSize: products.pagination.per_page,
      }
    },

    toPreview(id) {
      this.$refs.previewModal.open(id)
    },

    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },

    async removeItem(record) {
      try {
        await this.$services.delete(`admin/products/${record.id}`)
        await this.getList()
      } catch (e) {
        console.log(e)
      }
    },

    editItem(id) {
      this.$refs.editModal.open(id)
    },

    getCount(alias) {
      return this.statistics[alias] || 0
    },
  },
}
</script>

<style lang="scss" scoped>
.users_statistics {
  max-width: 313px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, .3);
  border-radius: 5px;
  &--item {
    display: flex;
    justify-content: space-between;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 4px;
    div:first-child {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
